<template>
  <section id="footer" class="flex bg-gray-100 md:border-l border-l-gray-300 -md:pt-3 py-10 relative min-h-[440px]">
    <div class="container grid md:grid-cols-12">
      <div class="flex flex-col justify-between md:col-span-6">
        <AtomTitle v-if="!success" tag="h3" class="text-gray-500 fade-item -md:mb-3">Посетить клуб</AtomTitle>
        <div class="flex flex-1">
          <AtomImage
            class="absolute bottom-[-34px] right-3 aspect-[213/23] max-w-[213px] md:static fade-item md:self-end"
            src="footer/icons.svg"
            height="24"
            width="214"
          />
        </div>
      </div>
      <div class="md:col-span-5">
        <client-only>
          <OrganismFooterForms @success="success = true" />
        </client-only>
      </div>
    </div>
  </section>
</template>

<script>
import AtomImage from '../../atoms/AtomImage'
import AtomTitle from '../../atoms/AtomTitle'
import OrganismFooterForms from '../../organisms/layouts/OrganismFooterForms'

export default {
  name: 'TemplateFooterForms',
  components: {
    OrganismFooterForms,
    AtomTitle,
    AtomImage,
  },
  data() {
    return {
      success: false,
    }
  },
}
</script>

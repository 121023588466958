import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2a183b29 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "" */))
const _28ebc8fb = () => interopDefault(import('../pages/timetable/index.vue' /* webpackChunkName: "" */))
const _48db8221 = () => interopDefault(import('../pages/timetable/_club/_age.vue' /* webpackChunkName: "" */))
const _610e5379 = () => interopDefault(import('../pages/tvtimetable/index.vue' /* webpackChunkName: "" */))
const _01fb1fd9 = () => interopDefault(import('../pages/team/index.vue' /* webpackChunkName: "" */))
const _124dfc9f = () => interopDefault(import('../pages/team/_club/index.vue' /* webpackChunkName: "" */))
const _398110d3 = () => interopDefault(import('../pages/team/_club/trainer/_trainer.vue' /* webpackChunkName: "" */))
const _693eca37 = () => interopDefault(import('../pages/career.vue' /* webpackChunkName: "" */))
const _300788fe = () => interopDefault(import('../pages/spa/index.vue' /* webpackChunkName: "" */))
const _3a785b22 = () => interopDefault(import('../pages/spa/list.vue' /* webpackChunkName: "" */))
const _28d94588 = () => interopDefault(import('../pages/spa/map.vue' /* webpackChunkName: "" */))
const _513fb2e6 = () => interopDefault(import('../pages/spa/_id.vue' /* webpackChunkName: "" */))
const _a840ec62 = () => interopDefault(import('../pages/contacts/index.vue' /* webpackChunkName: "" */))
const _17b7079e = () => interopDefault(import('../pages/contacts/list.vue' /* webpackChunkName: "" */))
const _eca572ce = () => interopDefault(import('../pages/contacts/map.vue' /* webpackChunkName: "" */))
const _669a2252 = () => interopDefault(import('../pages/clubs/index.vue' /* webpackChunkName: "" */))
const _584c5629 = () => interopDefault(import('../pages/clubs/list.vue' /* webpackChunkName: "" */))
const _f6b364be = () => interopDefault(import('../pages/clubs/map.vue' /* webpackChunkName: "" */))
const _71556262 = () => interopDefault(import('../pages/clubs/promo/_slug.vue' /* webpackChunkName: "" */))
const _a5e68a02 = () => interopDefault(import('../pages/clubs/_id.vue' /* webpackChunkName: "" */))
const _708134c2 = () => interopDefault(import('../pages/fitness/index.vue' /* webpackChunkName: "" */))
const _566f3214 = () => interopDefault(import('../pages/fitness/wellness-services.vue' /* webpackChunkName: "" */))
const _802c0ad0 = () => interopDefault(import('../pages/fitness/myzone.vue' /* webpackChunkName: "" */))
const _246078b0 = () => interopDefault(import('../pages/fitness/group-training.vue' /* webpackChunkName: "" */))
const _1618876e = () => interopDefault(import('../pages/fitness/mini-group.vue' /* webpackChunkName: "" */))
const _cd579992 = () => interopDefault(import('../pages/fitness/personal-training.vue' /* webpackChunkName: "" */))
const _3d3f3900 = () => interopDefault(import('../pages/fitness/testing.vue' /* webpackChunkName: "" */))
const _0b95ce44 = () => interopDefault(import('../pages/fitness/_city/_id/_page.vue' /* webpackChunkName: "" */))
const _cff352c6 = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "" */))
const _0a606064 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "" */))
const _774bf0f4 = () => interopDefault(import('../pages/franchise.vue' /* webpackChunkName: "" */))
const _0ab40050 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'link-active',
  linkExactActiveClass: 'link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _2a183b29,
    meta: {"name":"PageMain"},
    name: "home"
  }, {
    path: "/timetable",
    component: _28ebc8fb,
    meta: {"name":"Timetable","head":{"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"}]},"theme":"dark"},
    name: "timetable",
    children: [{
      path: "/timetable/:club/:age",
      component: _48db8221,
      meta: {"name":"TimetableClub","props":["context"]},
      name: "timetable_club"
    }]
  }, {
    path: "/tvtimetable/:club",
    component: _610e5379,
    meta: {"name":"TvTimetable","layout":"tv"},
    name: "tvtimetable"
  }, {
    path: "/team",
    component: _01fb1fd9,
    meta: {"name":"Team","theme":"dark"},
    name: "team",
    children: [{
      path: "/team/:club",
      component: _124dfc9f,
      meta: {"name":"TrainerList","props":["context"],"theme":"dark"},
      name: "team_club",
      children: [{
        path: "/team/:club/trainer/:trainer",
        component: _398110d3,
        meta: {"name":"STrainerList","theme":"dark"},
        name: "team_club_trainer"
      }]
    }]
  }, {
    path: "/career",
    component: _693eca37,
    meta: {"name":"Career","theme":"dark"},
    name: "career"
  }, {
    path: "/spa",
    component: _300788fe,
    meta: {"name":"SpaIndex","theme":"dark"},
    children: [{
      path: "",
      component: _3a785b22,
      meta: {"name":"SpaList","props":["context"],"theme":"dark"},
      name: "spa"
    }, {
      path: "/spa/map",
      component: _28d94588,
      meta: {"name":"SpaMap","props":{"context":{"required":false}},"theme":"dark"},
      name: "spa_map"
    }]
  }, {
    path: "/spa/:id",
    component: _513fb2e6,
    meta: {"name":"SpaId"},
    name: "spa_club"
  }, {
    path: "/contacts",
    component: _a840ec62,
    meta: {"name":"ContactsMain","theme":"dark"},
    children: [{
      path: "",
      component: _17b7079e,
      meta: {"name":"ContactsList","props":["context"],"theme":"dark"},
      name: "contacts"
    }, {
      path: "/contacts/map",
      component: _eca572ce,
      meta: {"name":"ContactsMap","props":{"context":{"required":false}},"theme":"dark"},
      name: "contacts_map"
    }]
  }, {
    path: "/clubs",
    component: _669a2252,
    meta: {"name":"ClubsIndex","theme":"dark"},
    children: [{
      path: "",
      component: _584c5629,
      meta: {"name":"ClubsList","props":["context"],"theme":"dark"},
      name: "clubs"
    }, {
      path: "/clubs/map",
      component: _f6b364be,
      meta: {"name":"ClubsMap","props":{"context":{"required":false}},"theme":"dark"},
      name: "clubs_map"
    }, {
      path: "/clubs/:id/promo/:slug",
      component: _71556262,
      meta: {},
      name: "clubs_club_promo"
    }]
  }, {
    path: "/clubs/:id",
    component: _a5e68a02,
    meta: {"name":"ClubsId"},
    name: "club"
  }, {
    path: "/fitness",
    component: _708134c2,
    meta: {"name":"Fitness","props":["context"],"theme":"dark"},
    name: "fitness"
  }, {
    path: "/fitness/wellness-services",
    component: _566f3214,
    meta: {"name":"WellnessServices","props":["context"],"theme":"dark"},
    name: "fitness_wellness-services"
  }, {
    path: "/fitness/myzone",
    component: _802c0ad0,
    meta: {"name":"FitnessMyzone"},
    name: "fitness_myzone"
  }, {
    path: "/fitness/group-training",
    component: _246078b0,
    meta: {"name":"FitnessGroup","theme":"dark"},
    name: "fitness_group-training"
  }, {
    path: "/fitness/mini-group",
    component: _1618876e,
    meta: {"name":"MiniGroup","theme":"dark"},
    name: "fitness_mini-group"
  }, {
    path: "/fitness/personal-training",
    component: _cd579992,
    meta: {"name":"FitnessPersonal","theme":"dark"},
    name: "fitness_personal-training"
  }, {
    path: "/fitness/testing",
    component: _3d3f3900,
    meta: {"name":"FitnessTesting","theme":"dark"},
    name: "fitness_testing"
  }, {
    path: "/fitness/:city/:id/:page",
    component: _0b95ce44,
    meta: {"name":"FitnessKids","theme":"dark"},
    name: "fitness_kids-clubs"
  }, {
    path: "/payment",
    component: _cff352c6,
    meta: {"name":"Payment","theme":"dark"},
    name: "payment"
  }, {
    path: "/about",
    component: _0a606064,
    meta: {"name":"About"},
    name: "about"
  }, {
    path: "/franchise",
    component: _774bf0f4,
    meta: {"name":"Franchise","theme":"dark"},
    name: "franchise"
  }, {
    path: "/privacy-policy",
    component: _0ab40050,
    meta: {"name":"PrivacyPolicy","theme":"dark"},
    name: "privacy-policy"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

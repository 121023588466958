import dayjs from 'dayjs'

const DAYS = [0, 1, 2, 3, 4, 5, 6]

export const state = () => ({
  date: null,
  classes: [],
  viewFilters: {},
  weekStartDate: null,
})

export const getters = {
  times(state, getters) {
    const times = {}
    Object.keys(getters.timetablesFiltered).forEach(day => {
      Object.keys(getters.timetablesFiltered[day]).forEach(time => {
        times[time] = true
      })
    })
    return Object.keys(times).sort()
  },
  day(state) {
    const date = dayjs(state.date)
    return {
      key: date.weekday(),
      day: date.format('dddd'),
      date: date.format('D MMMM'),
      dateFull: date.format('YYYY-MM-DD'),
      old: dayjs().isAfter(date, 'day'),
      today: dayjs().isSame(date, 'day'),
    }
  },
  days(state) {
    const startDate = dayjs(state.date).startOf('week')
    return DAYS.map(key => {
      const date = startDate.add(key, 'day')
      return {
        key: date.weekday(),
        day: date.format('dddd'),
        date: date.format('D MMMM'),
        old: dayjs().isAfter(date, 'day'),
        today: dayjs().isSame(date, 'day'),
      }
    })
  },
  categoriesItems(state, getters) {
    const categories = {}
    state.classes.forEach(item => {
      item.category.forEach(category => {
        const categoryId = category?.custom_field_id
        if (categoryId && !categories[categoryId]) {
          categories[categoryId] = {
            text: category.alias,
            slug: categoryId,
          }
        }
      })
    })
    return Object.values(categories)
  },
  zonesItems(state, getters) {
    const zones = {}
    state.classes.forEach(item => {
      const zoneId = item.place?.id
      if (zoneId && !zones[zoneId]) {
        zones[zoneId] = {
          text: item.place.name,
          slug: item.place.id,
        }
      }
    })
    return Object.values(zones)
  },
  trainingItems(state, getters) {
    const training = {}
    state.classes.forEach(item => {
      if (!training[item.training_id] && filterIsSameZone(state.viewFilters, item)) {
        training[item.training_id] = {
          text: item.name,
          slug: item.training_id,
        }
      }
    })
    return Object.values(training)
  },
  trainersItems(state, getters) {
    const trainers = {}
    state.classes.forEach(item => {
      if (
        item.trainer &&
        !trainers[item.trainer.id] &&
        filterIsSameZone(state.viewFilters, item) &&
        filterIsSameTraining(state.viewFilters, item)
      ) {
        const fio = [item.trainer.surname, item.trainer.name].filter(Boolean).join(' ')
        trainers[item.trainer.id] = {
          text: fio,
          slug: item.trainer.id,
        }
      }
    })
    return Object.values(trainers)
  },
  timetablesFiltered(state, getters) {
    return mapClassesToTimetable(state.classes, item => {
      return (
        filterIsSameCategory(state.viewFilters, item) &&
        filterIsSameZone(state.viewFilters, item) &&
        filterIsSameTraining(state.viewFilters, item) &&
        filterIsSameTrainer(state.viewFilters, item) &&
        filterIsPaid(state.viewFilters, item) &&
        filterIsMiniGroup(state.viewFilters, item) &&
        filterIsSection(state.viewFilters, item)
      )
    })
  },
  timetables(state, getters) {
    return mapClassesToTimetable(state.classes)
  },
}

export const mutations = {
  SET_DATE(state, date) {
    state.date = date
  },
  SET_ITEMS(state, data) {
    state.classes = data
  },
  CLEAR_ITEMS(state) {
    state.classes = []
  },
  SET_VIEW_FILTERS(state, data) {
    state.viewFilters = data
  },
  SET_WEEK_START_DATE(state, data) {
    state.weekStartDate = data
  },
}

export const actions = {
  async getTimetable({ commit, state }, { params, conditions }) {
    try {
      const date = dayjs(params.date, 'YYYY-MM-DD', true)
      const today = dayjs().format('YYYY-MM-DD')
      commit('SET_DATE', (date.isValid() && params.date) || today)
      if (conditions?.requestIfOutOfWeek) {
        const isSameWeek = dayjs(params.date).isSame(state.weekStartDate, 'week')
        if (isSameWeek) return true
      }
      const dateRangeParams = date.isValid()
        ? {
            dateFrom: date.startOf('week').format('YYYY-MM-DD'),
            dateTo: date.endOf('week').format('YYYY-MM-DD'),
          }
        : {
            dateFrom: dayjs().startOf('week').format('YYYY-MM-DD'),
            dateTo: dayjs().endOf('week').format('YYYY-MM-DD'),
          }
      commit('SET_WEEK_START_DATE', dateRangeParams.dateFrom)
      const response = await this.$axios({
        url: `/api/fitbase/${params.club}/classes`,
        method: 'get',
        params: {
          ...dateRangeParams,
          'filters[age]': params.age,
          // 'filters[zones]': params.zones,
          // 'filters[training]': params.training,
          // 'filters[trainer]': params.trainer,
          // 'filters[categories]': params.categories,
          // 'filters[minigroups]': params.minigroup ? 1 : undefined,
          // 'filters[sections]': params.sections ? 1 : undefined,
          // 'filters[paid]': params.paid,
        },
      })
      const data = response.data || []

      const weekNotChanged = dayjs(params.date).isSame(state.weekStartDate, 'week')
      if (weekNotChanged) {
        commit('SET_ITEMS', data)
      }

      return true
    } catch (e) {
      commit('CLEAR_ITEMS')
      return false
    }
  },
  setViewFilters({ commit }, data) {
    commit('SET_VIEW_FILTERS', data)
  },
}

function mapClassesToTimetable(classes = [], predicate = () => true) {
  const daysTable = {}

  classes.forEach(item => {
    if (!predicate(item)) return
    const day = dayjs(`${item.date}T${item.time_start}`)
    const dayEnd = dayjs(`${item.date_end}T${item.time_end}`)
    const weekday = day.weekday()
    if (!daysTable[weekday]) {
      daysTable[weekday] = {}
    }
    const tableDay = daysTable[weekday]
    const hour = (item.time_start || '00').split(':')[0]
    if (!tableDay[`${hour}:00`]) {
      tableDay[`${hour}:00`] = []
    }

    let progress = [];
    const { scale: customFields } = item
    if (customFields && customFields !== null) {
      progress =
        customFields
          .map(item => ({ title: item.name || null, progress: parseInt(item.key?.value) || null }))
          .filter(item => item.progress !== null && item.title !== null) || []
    }

    let categoryType = ''
    const types = []

    if (item.minigroup) {
      categoryType = 'Мини-группа'
      types.push({
        title: 'Мини-группы',
        to: `/timetable/${item.club.slug}/adult?minigroup=true`,
      })
    }

    if (item.section) {
      categoryType = 'Секция'
      types.push({
        title: 'Секции',
        to: `/timetable/${item.club.slug}/adult?section=true`,
      })
    }

    if (item.category?.length) {
      item.category.forEach(category => {
        types.push({
          title: category.alias,
          to: `/timetable/${item.club.slug}/adult?categories=${category.custom_field_id}`,
        })
      })
    }

    tableDay[`${hour}:00`].push({
      old: dayjs().isAfter(day),
      today: dayjs().isSame(day, 'day'),
      day: day.format('dddd, D MMMM'),
      time: day.format('HH:mm'),
      timeEnd: dayEnd.format('HH:mm'),
      meet: !item.is_online,
      paid: item.payed !== 'free',
      name: item.name || '',
      trainer: item.trainer || {},
      training_id: item.training_id,
      place: item.place || {},
      image: item.image || item.trainer?.photo || null,
      cabinet: item.club.cabinet,
      text: {
        title: 'О программе',
        text: item.description,
      },
      item,
      section: item.section,
      minigroup: item.minigroup,
      specialgroup: item.specialgroup,
      categoryType,
      category: item.category || [],
      progress,
      tags: [
        types.length && {
          title: 'Направления',
          controls: types.filter(Boolean),
        },
        item.club && {
          title: 'Клуб',
          controls: [
            {
              title: item.club.title,
              to: `/timetable/${item.club.slug}/adult`,
            },
            item.place && {
              title: item.place.name,
              to: `/timetable/${item.club.slug}/adult?zone=${item.place.id}`,
            },
          ].filter(Boolean),
        },
        item.trainer && {
          title: 'Тренер',
          controls: [
            {
              title: `${item.trainer.name} ${item.trainer.surname}`,
              to: `/team/${item.club.slug}/trainer/${item.trainer.id}`,
            },
          ],
        },
      ].filter(Boolean),
    })
  })
  return daysTable
}

function filterIsSameCategory(viewFilters, item) {
  if (typeof viewFilters.categories !== 'string') return true
  return (item.category || []).find(category => category?.custom_field_id == viewFilters.categories)
}

function filterIsSameZone(viewFilters, item) {
  if (typeof viewFilters.zone !== 'string') return true
  return item.place?.id == viewFilters.zone
}

function filterIsSameTraining(viewFilters, item) {
  if (typeof viewFilters.training !== 'string') return true
  return item.training_id == viewFilters.training
}

function filterIsSameTrainer(viewFilters, item) {
  if (typeof viewFilters.trainer !== 'string') return true
  return item.trainer?.id == viewFilters.trainer
}

function filterIsPaid(viewFilters, item) {
  if (typeof viewFilters.paid !== 'string') return true
  return viewFilters.paid === 'true' && item.paidgroup
}

function filterIsMiniGroup(viewFilters, item) {
  if (typeof viewFilters.minigroup !== 'string') return true
  return viewFilters.minigroup === 'true' && !!item.minigroup
}

function filterIsSection(viewFilters, item) {
  if (typeof viewFilters.sections !== 'string') return true
  return viewFilters.sections === 'true' && !!item.section
}

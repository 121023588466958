export const state = () => ({
  cityList: [],
  clubs: [],
})

export const getters = {
  getСityList(state, getters) {
    return state.cityList
  },
  getClubs(state, getters) {
    return state.clubs
  },
}

export const mutations = {
  SET_ClUBS(state, payload) {
    state.clubs = payload
  },
  SET_ITEM_CITY(state, payload) {
    state.cityList = payload
  },
  CLEAR_ITEM_CITY(state) {
    state.cityList = {}
  },
  CLEAR_ClUBS(state) {
    state.clubs = []
  },
}

export const actions = {
  async fetchCity({ commit }, filterTypeField) {
    try {
      const response = await this.$axios({
        url: `/api/city`,
        method: 'get',
      })

      let data = response.data || {}

      if (filterTypeField) {
        data = data.filter(city => city[filterTypeField])
      }
      data = data.map(({ id, title, slug }) => ({ id, slug, text: title }))

      commit('SET_ITEM_CITY', data)
      return data
    } catch (e) {
      commit('CLEAR_ITEM_CITY')
      return null
    }
  },
  async fetchClubs({ commit }, params) {
    try {
      const response = await this.$axios({
        url: `/api/clubs${params.cityId ? `?filters[city]=${params.cityId}` : ''}`,
        method: 'get',
      })
      let data = response.data || {}

      if (params?.filter) {
        data = data.filter(club => club[params.filter])
      }

      const clubsList = data.filter(item => item.slug).map(({ id, slug, title }) => ({ id, slug, text: title }))

      commit('SET_ClUBS', clubsList)
      return data
    } catch (e) {
      commit('CLEAR_CLUBS')
      return null
    }
  },
}

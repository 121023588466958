export function getClubGoalName(slug) {
  switch (slug) {
    case 'encore_city':
      return 'form_encore_city'
    case 'encore_yasenevo':
      return 'form_encore_yasenevo'
    case 'encore_khodynka':
      return 'form_encore_khodynka'
    case 'encore_vasilevskiy':
      return 'form_encore_vasilevskiy'
    case 'encore_iset':
      return 'form_encore_iset'
    case 'encore_sochi':
      return 'form_encore_san_city'
    case 'encore_krestovsky':
      return 'form_encore_krestovskiy'
    default:
      throw new Error(`Unknown club slug for ym: ${slug}`)
  }
}

<template>
  <div
    class="overflow-hidden pointer-events-none"
    :class="{ '!mt-[-2vw]': tag === 'h1', '!mt-[-1.8vw]': tag === 'h2', [classWrap]: true }"
  >
    <component
      :is="tag"
      data-scroll
      :data-scroll-offset="offset"
      :data-scroll-class="`${animation}-call`"
      class="overflow-hidden relative origin-top-left pr-[0.08em] will-change-transform print:!pr-[4px]"
      :class="{
        [animation]: true,
        [`animation-delay-${delay}`]: true,
        [className]: true,
        '!pt-[2vw]': tag === 'h1',
        '!pt-[1.8vw]': tag === 'h2',
      }"
    >
      <span v-if="content" class="print:!relative print:!top-[-12px]" v-html="content" />
      <span class="hidden print:!inline-block print:!text-[14px] print:!relative print:!top-[-12px] print:left-[2px]"
        >В</span
      >
      <span class="whitespace-pre-wrap"><slot /></span>
      <sup
        v-if="sup"
        class="tracking-tight md:tracking-tighter text-[12px] md:text-lg"
        :class="{ [classNameSup]: true }"
        v-html="sup"
      />
    </component>
  </div>
</template>

<script>
export default {
  name: 'AtomTitle',
  props: {
    tag: {
      type: String,
      default: 'h1',
    },
    content: {
      type: String,
      default: '',
    },
    classWrap: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    sup: {
      type: String,
      default: '',
    },
    classNameSup: {
      type: String,
      default: '',
    },
    animation: {
      type: String,
      default: 'none',
    },
    delay: {
      type: String,
      default: '0',
    },
    offsetZero: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    offset() {
      if (this.offsetZero) return '0%,0%'
      if (!process.client) return '20%,20%'
      const offset = Math.floor((window.innerWidth / window.innerHeight) * 8)
      return `${offset}%,${offset}%`
    },
  },
}
</script>

<style lang="postcss" scoped>
.custom-title {
  @apply overflow-visible ml-[-2vw];
  big {
    @apply overflow-visible;
  }
  sup {
    @apply absolute left-[97%];
  }
}
h1 {
  @apply uppercase pr-[0.08em] py-[0.08em];
}

h2 {
  @apply py-[0.07em] my-[-0.07em];
  sup {
    @apply !text-[12px] !left-0;
    ::v-deep svg {
      @apply inline-block;
    }
  }
}

h1,
h2,
big {
  sup {
    @apply top-0 pr-[0.65em] lg:pr-[0.2em] left-1 md:left-3 align-top inline-block;
    ::v-deep svg {
      @apply inline-block align-top;
    }
  }
}

big {
  @apply text-[22vw] md:text-[15.694vw] inline-block ml-[-0.04em];
  line-height: 85%;
  letter-spacing: -0.09em;
  /* padding: 0 0.09em; */

  sup {
    @apply text-xl;
  }
}

.animation-delay-0 {
  animation-delay: 0;
}
.animation-delay-100 {
  animation-delay: 0.1s;
}
.animation-delay-200 {
  animation-delay: 0.2s;
}
.animation-delay-300 {
  animation-delay: 0.3s;
}
.animation-delay-400 {
  animation-delay: 0.4s;
}
.animation-delay-500 {
  animation-delay: 0.5s;
}

.rotation {
  transform: rotate(5deg) translateY(95%);
}
.rotation-call {
  animation-name: rotation;
  animation-duration: 0.6s;
  animation-timing-function: var(--cubic-bezier);
  animation-fill-mode: forwards;
}

@keyframes rotation {
  from {
    transform: rotate(5deg) translateY(95%);
  }
  to {
    transform: rotate(0deg) translateY(0);
  }
}

.fade {
  transform: translateY(20%);
  opacity: 0.2;
}

.fade-call {
  animation-name: fade;
  animation-duration: 0.6s;
  animation-timing-function: var(--cubic-bezier);
  animation-fill-mode: forwards;
}

@keyframes fade {
  from {
    transform: translateY(20%);
    opacity: 0.2;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>

<template>
  <MoleculeFooterLastLink :links="links" :links-groups="linksGroup" />
</template>

<script>
import MoleculeFooterLastLink from '../../molecules/layouts/MoleculeFooterLastLink'

export default {
  name: 'TemplateFooterLastNav',
  components: {
    MoleculeFooterLastLink,
  },
  computed: {
    links() {
      return [
        {
          content: `@ ${new Date().getFullYear()} Encore Fitness. <br class="md:hidden"/> Все&nbsp;права защищены`,
          class: '-md:order-3',
        },
        {
          to: '/privacy-policy',
          content: 'Политика',
          class: '-md:order-2 col-[1_/_3]',
        },
        {
          to: '/payment',
          content: 'Способы оплаты',
          class: '-md:order-1 col-[1_/_3]',
        },
        // {
        //   to: '/',
        //   content: 'Switch to English',
        //   class: '-md:hidden',
        // },
      ].filter(Boolean)
    },
    linksGroup() {
      return [
        {
          to: 'https://arcr.ru/',
          group: true,
          content: 'Design by ARCR',
          class: '-md:order-4 -md:justify-end -md:self-end',
        },
        {
          to: 'https://brick.dev/',
          group: true,
          content: 'Dev by BRICK',
          class: '-md:order-4 -md:justify-end -md:self-end',
        },
      ].filter(Boolean)
    },
  },
}
</script>
